/**
 * Author: Nova Biomatique
 * Module/App: Notifications.socket.io Js
 */


!function ($) {
    'use strict';

    var SocketApp = function () {
        this.$body = $('body'),
            this.$window = $(window),
            this.$badge = $('span.noti-icon-badge')
    };

    /**
     * Get notifications.
     */
    SocketApp.prototype.getNotifications = function (siteId, translate) {

        axios.get('/notifications/' + siteId + '/get').then(function (response) {

            var notifications = response.data.reverse();
            var html = '';

            notifications.forEach(function (notif) {
                if (notif.type == 1) {
                    html = $.SocketApp.addHighLdvtTemperatureAlert(notif.value, notif.description, notif.extraInfo);
                    $.SocketApp.getNotified(html, notif.active);
                }
                else if (notif.type == 2) {
                    html = $.SocketApp.addHighTemperatureAlert(notif.value, notif.description, notif.extraInfo);
                    $.SocketApp.getNotified(html, notif.active);
                }
                else if (notif.type == 3) {
                    html = $.SocketApp.addHighTemperatureWarning(notif.value, notif.description, notif.extraInfo);
                    $.SocketApp.getNotified(html, notif.active);
                }
                else if (notif.type == 4) {
                    html = $.SocketApp.addColdTemperatureWarning(notif.value, notif.description, notif.extraInfo);
                    $.SocketApp.getNotified(html, notif.active);
                }
                else if (notif.type == 5) {
                    html = $.SocketApp.addDisconnectedGatewayAlert(notif.description, notif.extraInfo);
                    $.SocketApp.getNotified(html, notif.active);
                }
                else if (notif.type == 6) {
                    html = $.SocketApp.addDisconnectedProbesAlert(notif.description, notif.extraInfo);
                    $.SocketApp.getNotified(html, notif.active);
                }
            });
        }).catch(function (err) {
            $.NotificationApp.send(translate["Oops!"], translate['An error occured, please contact admin'], 'bottom-center', 'rgba(0,0,0,0.2)', 'error');
        });
    },

    /**
     * High LDVT temperature alert HTML.
     */
    SocketApp.prototype.addHighLdvtTemperatureAlert = function (ldvt, message, pileId) {
        return '<a href="/piles/' + pileId + '/edit" class="dropdown-item notify-item"><i style="color:red;" class="uil-arrow-growth text-danger"></i><small class="text-muted"> ' + ((ldvt > 0) ? '+' : '') + ((ldvt) ? ldvt.toFixed(1).replace('.', ',') : '-') + ' °C</small><div class="notify-icon bg-danger"><i class="mdi mdi-coolant-temperature"></i></div><p class="notify-details">' + message + '</p></a>';
    },

    /**
     * High temperature alert HTML.
     */
    SocketApp.prototype.addHighTemperatureAlert = function (temp, message, pileId) {
        return '<a href="/piles/' + pileId + '/edit" class="dropdown-item notify-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill-opacity=".1" d="M2 22h20V2z"/><path fill="#ff6600" d="M17 7L2 22h15z"/><path d="M0 0h24v24H0z" fill="none"/></svg><small class="text-muted">' + ((temp) ? temp : '-') + ' °C</small><div class="notify-icon bg-danger"><i class="mdi mdi-coolant-temperature"></i></div><p class="notify-details">' + message + '</p></a>';
    },

    /**
     * High temperature warning HTML.
     */
    SocketApp.prototype.addHighTemperatureWarning = function (temp, message, pileId) {
        return '<a href="/piles/' + pileId + '/edit" class="dropdown-item notify-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill-opacity=".1" d="M2 22h20V2z"/><path fill="#fe9900" d="M14 10L2 22h12z"/><path d="M0 0h24v24H0z" fill="none"/></svg><small class="text-muted">' + ((temp) ? temp : '-') + ' °C</small><div class="notify-icon bg-warning"><i class="mdi mdi-coolant-temperature"></i></div><p class="notify-details">' + message + '</p></a>';
    },

    /**
     * Cold temperature warning HTML.
     */
    SocketApp.prototype.addColdTemperatureWarning = function (temp, message, pileId) {
        return '<a href="/piles/' + pileId + '/edit" class="dropdown-item notify-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill-opacity=".1" fill="#000000" d="M2 22h20V2z"/><path d="M0 0h24v24H0z" fill="none"/></svg><small class="text-muted">' + ((temp) ? temp : '-') + ' °C</small><div class="notify-icon bg-info"><i class="mdi mdi-coolant-temperature"></i></div><p class="notify-details">' + message + '</p></a>';
    },

    /**
     * Disconnected gateway alert HTML.
     */
    SocketApp.prototype.addDisconnectedGatewayAlert = function (message, siteName) {
        return '<a href="/gateways" class="dropdown-item notify-item"><small class="text-muted">Site: ' + ((siteName) ? siteName : '') + '</small><div class="notify-icon bg-danger"><i class="uil-wifi-slash"></i></div><p class="notify-details">' + message + '</p></a>';
    },

    /**
     * Disconnected probes alert HTML.
     */
        SocketApp.prototype.addDisconnectedProbesAlert = function (message, siteName) {
        return '<a href="/probes" class="dropdown-item notify-item"><small class="text-muted">Site: ' + ((siteName) ? siteName : '') + '</small><div class="notify-icon bg-danger"><i class="uil-wifi-slash"></i></div><p class="notify-details">' + message + '</p></a>';
    },

    /**
     * Add notification according to notification type.
     */
    SocketApp.prototype.checkTemperatureNotificationType = function (type, temperature, message, pileId) {
        var html = '';

        switch (type) {
            case 1:
                html = $.SocketApp.addHighLdvtTemperatureAlert(temperature, message, pileId);
                $.SocketApp.getNotified(html, true);
                break;
            case 2:
                html = $.SocketApp.addHighTemperatureAlert(temperature, message, pileId);
                $.SocketApp.getNotified(html, true);
                break;
            case 3:
                html = $.SocketApp.addHighTemperatureWarning(temperature, message, pileId);
                $.SocketApp.getNotified(html, true);
                break;
            case 4:
                html = $.SocketApp.addColdTemperatureWarning(temperature, message, pileId);
                $.SocketApp.getNotified(html, true);
                break;
        }
    },

    /**
     * Get notified.
     */
    SocketApp.prototype.getNotified = function (html, isNew) {
        // Add line to bell list
        $('#notifications-list').find('.simplebar-content').prepend(html);

        if (isNew) {
            this.$badge.show();
        }
    },

    /**
     * initilizing
     */
    SocketApp.prototype.init = function () {
        var $this = this;

        $(document).ready(function() {

            if (typeof io !== 'undefined') {

                var translate = $.Components.translate();

                var currentSiteId = $('#site').text();
                var currentCompanyId = $('#company').text();
                var userRole = $('.account-position:eq(0)').text();

                $.SocketApp.getNotifications(currentSiteId, translate);

                var socket = io.connect(window.location.origin, { secure: true, reconnect: true, rejectUnauthorized: false });

                socket.on('getTemperatureNotification', function (notificationType, temperature, message, companyId, siteId, pileId) {

                    if (userRole.includes('user') && currentSiteId == siteId) {
                        $.SocketApp.checkTemperatureNotificationType(notificationType, temperature, message, pileId);
                    }
                    else if ((userRole == 'Root' || userRole == 'Admin') && currentCompanyId == companyId) {
                        $.SocketApp.checkTemperatureNotificationType(notificationType, temperature, message, pileId);
                    }
                })
                .on('getDisconnectedGatewayNotification', function (message, companyId, siteId, siteName) {

                    if (userRole.includes('user') && currentSiteId == siteId) {

                        var html = $.SocketApp.addDisconnectedGatewayAlert(message, siteName);
                        $.SocketApp.getNotified(html, true);
                    }
                    else if ((userRole == 'Root' || userRole == 'Admin') && currentCompanyId == companyId) {

                        var html = $.SocketApp.addDisconnectedGatewayAlert(message, siteName);
                        $.SocketApp.getNotified(html, true);
                    }
                })
                .on('getDisconnectedProbesNotification', function (message, siteName, companyId, siteId) {

                    if (userRole.includes('user') && currentSiteId == siteId) {

                        var html = $.SocketApp.addDisconnectedProbesAlert(message, siteName);
                        $.SocketApp.getNotified(html, true);
                    }
                    else if ((userRole == 'Root' || userRole == 'Admin') && currentCompanyId == companyId) {

                        var html = $.SocketApp.addDisconnectedProbesAlert(message, siteName);
                        $.SocketApp.getNotified(html, true);
                    }
                });

                $('#clear-all-notifications').on('click', function () {

                    axios.delete('/notifications/delete')
                        .then(function (response) {

                            $('#notifications-list').find('.simplebar-content').empty();
                            $.SocketApp.$badge.hide();
                            $.NotificationApp.send(translate['Success!'], response.data.description, 'bottom-center', 'rgba(0,0,0,0.2)', 'success');
                        })
                        .catch(function (err) {
                            $.NotificationApp.send(translate['Oops!'], translate['An error occured, please contact admin'], 'bottom-center', 'rgba(0,0,0,0.2)', 'error');
                        });
                });
            }
        });
    },

    $.SocketApp = new SocketApp, $.SocketApp.Constructor = SocketApp
}(window.jQuery),

    //initializing socket application module
    function ($) {
        "use strict";
        $.SocketApp.init();
    }(window.jQuery);